import React from "react"

import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const SymptomCheckboxItem = ({ id, label, onToggle, checked }) => {
  return (
    <div css={styles.checkboxItem}>
      <input
        css={styles.checkbox}
        type="checkbox"
        onChange={onToggle}
        id={id}
        checked={checked}
      />
      <label htmlFor={id} css={styles.checkboxItemlabel}>
        {label}
      </label>
    </div>
  )
}

const styles = {
  checkboxItem: css({
    padding: "0.5rem 1rem 0.5rem 0",
    position: "relative",
    breakInside: "avoid",
  }),
  checkbox: css({
    margin: "0",
    padding: "0",
    appearance: "none",
    position: "absolute",
    left: "0",
    top: "50%",
    borderRadius: "4px",
    background: theme.colours.white,
    verticalAlign: "middle",
    width: "2rem",
    height: "2rem",
    transform: "translateY(-50%)",
    cursor: "pointer",
    "&:checked:after": {
      content: '""',
      display: "block",
      background: theme.colours.red,
      borderRadius: "2px",
      position: "absolute",
      height: "1rem",
      width: "1rem",
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      zIndex: 1,
    },
  }),
  checkboxItemlabel: css({
    color: theme.colours.black,
    display: "inline-block",
    margin: 0,
    padding: "0 0 0 3rem",
    cursor: "pointer",
  }),
}

export default SymptomCheckboxItem
