import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import DefaultLayoutSidebar from "../../components/DefaultLayoutSidebar"
import CustomNavTitle from "../../components/CustomNavTitle"
import SymptomCheckboxItem from "../../components/SymptomCheckboxItem"

import { theme } from "../../styles/theme"

import illustration2x from "../../images/illustrations/illustration-transfusion-reaction-symptoms@2x.png"

const OtherSymptoms = ({ location }) => {
  let defaultSymptoms = [
    {
      id: "tempGTE39",
      label: "Temp ≥39ºC or rise ≥2ºC",
      checked: false,
    },
    {
      id: "tempGTE38",
      label: "Temp ≥38ºC and rise ≥1-2ºC",
      checked: false,
    },
    {
      id: "rash",
      label: "Rash/urticaria",
      checked: false,
    },
    {
      id: "chills",
      label: "Chills/rigors",
      checked: false,
    },
    {
      id: "newPain",
      label: "New pain (loin/back/abdo/joint)",
      checked: false,
    },
    {
      id: "anxiety",
      label: "Anxiety",
      checked: false,
    },
    {
      id: "nausea",
      label: "Nausea",
      checked: false,
    },
    {
      id: "fallUrine",
      label: "A fall in urine output",
      checked: false,
    },
    {
      id: "haemoglobinuria",
      label: "Haemoglobinuria",
      checked: false,
    },
  ]

  if (location.state && location.state.symptoms)
    defaultSymptoms = location.state.symptoms

  const [symptoms, setSymptoms] = useState(defaultSymptoms)

  const [disableLink, setDisableLink] = useState(true)

  const [destination, setDestination] = useState(
    "/transfusion-reaction/next-steps"
  )

  const updateLocation = () => {
    // If only one item selected, and it is not haemoglobinuria or fallUrine, destination is NextSteps, otherwise it is ImmediateActions
    let checkedOptions = symptoms
      .filter(symptom => {
        return symptom.checked ? symptom.id : false
      })
      .map(s => s.id)

    setDisableLink(checkedOptions.length === 0)

    checkedOptions.length === 1 &&
    checkedOptions.indexOf("haemoglobinuria") === -1 &&
    checkedOptions.indexOf("fallUrine") === -1
      ? setDestination("/transfusion-reaction/next-steps")
      : setDestination("/transfusion-reaction/immediate-actions")
  }

  const handleSubmission = ev => {
    ev.preventDefault()
  }

  const handleToggle = id => {
    let newSymptomsArr = [...symptoms]
    newSymptomsArr.forEach(sym => {
      if (sym.id === id) {
        sym.checked = !sym.checked
      }
    })
    setSymptoms(newSymptomsArr)
    updateLocation()
  }

  useEffect(updateLocation, [])

  return (
    <Layout>
      <SEO title="Transfusion Reaction Other Symptoms" />

      <CustomNavTitle
        title="Transfusion Reaction"
        destination="/transfusion-reaction/"
        location={{
          state: {
            ...location.state,
            backOverride: "/transfusion-reaction/",
          },
        }}
      />

      <DefaultLayoutSidebar>
        <section className="max-w-content">
          <article css={styles.container}>
            <header css={styles.header}>
              <p css={styles.letter}>O</p>
              <h2 css={styles.title}>Other Symptoms</h2>
            </header>

            <form css={styles.form} onSubmit={handleSubmission}>
              <p css={styles.formIntro}>Please check all that apply.</p>

              <div css={styles.checkboxContainer}>
                {symptoms.map(item => (
                  <SymptomCheckboxItem
                    key={item.id}
                    id={item.id}
                    label={item.label}
                    onToggle={() => handleToggle(item.id)}
                    checked={item.checked}
                  />
                ))}
              </div>

              <Link
                to={destination}
                state={{
                  backOverride: "/transfusion-reaction/other-symptoms",
                  symptoms,
                }}
                css={[
                  theme.components.button,
                  styles.formSubmit,
                  disableLink && styles.disabled,
                ]}
              >
                Continue
              </Link>
            </form>
          </article>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

const styles = {
  container: css({
    background: theme.colours.lightGrey,
    borderRadius: theme.borderRadius,
    paddingBottom: theme.gap,
    overflow: "hidden",
  }),
  header: css({
    display: "flex",
    alignItems: "center",
    borderBottom: "2px solid #fff",
  }),
  letter: css({
    fontSize: theme.fzH1,
    fontWeight: "bold",
    color: theme.colours.red,
    padding: theme.gapHalf,
    borderRight: "2px solid #fff",
    flex: "0 0 auto",
  }),
  title: css({
    color: theme.colours.red,
    fontSize: theme.fzH2,
    padding: theme.gapHalf,
    flexGrow: 1,
  }),
  form: css({
    padding: theme.gap,
  }),
  formIntro: css({
    color: theme.colours.black,
  }),
  checkboxContainer: css({
    marginTop: theme.gapHalf,
    columns: 2,
  }),
  formSubmit: css({
    marginTop: theme.gapDouble,
    cursor: "pointer",
  }),
  disabled: css({
    opacity: 0.6,
    pointerEvents: "none",
    backgroundColor: theme.midGrey,
  }),
}

export default OtherSymptoms
